<template>
  <v-row align="center" justify="space-between">
    <v-col
      v-for="(service, idx) in services"
      :key="idx"
      cols="12"
      md="6"
    >
      <v-card
        outlined
        tile
        class="pa-2 text-center d-flex flex-column rounded-lg transbg">
        <div class="d-flex align-center justify-space-between">
          <span class="font-weight-bold">{{ service.name }}</span>
          <component :is="dictionary[service.status]" />
        </div>
        <div class="d-flex align-center justify-start text-caption ">
          <p class="mb-0">
            {{issueDescription(service)}}
          </p>
          <v-btn
              v-if="service.link.length"
            link
            color="info"
            x-small
            text
            @click="openIncident(service)"
          >
            {{ linkTitle(service) }}
          </v-btn>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ok from '@/components/tooltipIcons/ok'
import degradation from '@/components/tooltipIcons/degradation'
import thirdparty from '@/components/tooltipIcons/thirdparty'
import downtime from '@/components/tooltipIcons/downtime'
import maintenance from '@/components/tooltipIcons/maintenanceIcon'
import suspected from '@/components/tooltipIcons/suspected'

export default {
  name: 'StatusList',
  components: {
    ok,
    degradation,
    thirdparty,
    downtime,
    maintenance,
    suspected
  },
  props: {
    services: {
      type: Array,
      default: null
    }
  },
  data: () => ({
    dictionary: {
      'Ok': ok,
      'Degradation': degradation,
      '3rd Party': thirdparty,
      'Downtime': downtime,
      'Maintenance':maintenance,
      'Suspected': suspected
    }
  }),
  methods: {
    openIncident (service) {
        if (service.link.length > 1){
          this.$emit('showCurrentStatus')
        }
        else {
        if (service.status === 'Maintenance') {
          this.$emit('showMaintenance', service.link[0])
        } else {
          this.$emit('showIncident', service.link[0])
        }
      }
    },

      issueDescription(service){
        if (service.link.length > 0){
          return service.status === 'Maintenance' ? 'Maintenance is being performed' : "Something's not quite right"
        }
        else return 'No issues'
      },

    linkTitle(service){
        return service.status === 'Maintenance' ? 'View Maintenance' : "View Incident"
    }


  },
}
</script>

<style scoped>

</style>
