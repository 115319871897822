<template>
  <v-dialog
      v-model="dialog"
      max-width="450"
      @click:outside="dialog = false"
  >
    <template #activator="{ on, attrs }">
      <v-btn
          block
          rounded
          color="accent"
          v-bind="attrs"
          v-on="on"
          @click="dialog = true"
      >
        Subscribe to get updates
      </v-btn>
    </template>
    <v-card color="darkest">
      <div class="pa-3 d-flex justify-space-between align-center">
        <v-tabs
            v-model="selectedTab"
            background-color="transparent"
            color="info"
        >
          <v-tab>E-mail</v-tab>
          <v-tab>Phone</v-tab>
        </v-tabs>
        <v-spacer />
        <v-btn
            icon
            color="secondary"
            @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div>
        <v-tabs-items
            v-model="selectedTab"
            transition="fade-transition"
            class="transparent secondary--text pa-3 mt-4"
        >
          <v-tab-item>
            <v-form
                v-if="!emailPendingSub"
                ref="emailForm"
                @submit.prevent="subscribeEmail"
            >
              <p>Receive email notifications when LivePerson creates, updates, and resolves incidents.</p>
              <v-text-field
                  v-model="email"
                  color="secondary"
                  :rules="rules"
                  placeholder="username@youremail.com"
                  dense
                  outlined
                  filled
              />

              <div class="d-flex justify-center align-center">
                <v-btn
                    color="accent"
                    rounded
                    class="mb-3"
                    type="submit"
                    :loading="loading"
                    @click="loader = 'loading'"
                >
                  Subscribe
                </v-btn>
              </div>
            </v-form>
            <div v-if="emailPendingSub">
              <p>Email Confirmation Sent, please check your inbox and confirm your subscription.</p>
            </div>
          </v-tab-item>
          <v-tab-item>
            <v-form
                v-if="!phonePendingSub"
                ref="phoneForm"
                @submit.prevent="subscribePhone"
            >
              <p>Receive SMS notifications when LivePerson creates, updates, and resolves incidents.</p>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                      ref="autocomplete"
                      v-model="countryCode"
                      :items="codes"
                      :rules="phoneRules"
                      color="secondary"
                      placeholder="Area code"
                      dense
                      outlined
                      filled
                      hide-details
                      item-color="white"
                      :menu-props="{
                      offsetY: true,
                    }"
                      @change="setCode"
                  >
                    <template #prepend-item>
                      <v-list-item
                          color="secondary"
                          class="primary"
                      >
                        <span>Favorites:</span>
                      </v-list-item>
                      <v-list-item
                          v-for="(name, index) in favorites"
                          :key="index"
                          color="secondary"
                          class="primary"
                          @click="setCode(name)"
                      >
                        <span>{{ name }}</span>
                      </v-list-item>
                      <v-divider class="secondary" />
                    </template>
                    <template #item="{on, attrs, item}">
                      <v-list-item
                          v-bind="attrs"
                          color="secondary"
                          class="primary"
                          v-on="on"
                      >
                        <span>{{ item }}</span>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="phone"
                      color="secondary"
                      placeholder="ex. +33324773923"
                      :rules="phoneRules"
                      dense
                      outlined
                      filled
                  />
                </v-col>
                <v-col
                    cols="12"
                    class="d-flex justify-center align-center"
                >
                  <v-btn
                      color="accent"
                      rounded
                      class="mb-3"
                      type="submit"
                      :loading="loading"
                      @click="loader = 'loading'"
                  >
                    Subscribe
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <div v-if="phonePendingSub">
              <p>SMS Message Confirmation Sent, please check your device and confirm your subscription.</p>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <recaptcha ref="recaptcha" @verify="onVerify"></recaptcha>
    </v-card>
  </v-dialog>
</template>

<script>
import codes from '@/plugins/countryCodes'
import recaptcha from '@/components/interface/recaptcha';
import { mapActions } from 'vuex'

export default {
  name: 'SubscribeModal',
  components: { recaptcha },
  data: () => ({
    loader: null,
    loading: false,
    emailPendingSub: false,
    phonePendingSub: false,
    selectedTab: null,
    email: '',
    codes: [],
    favorites: [],
    phone: '',
    dialog: false,
    countryCode: '',
    rules: [
      value => !!value || 'Required.',
      (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      }
    ],
    phoneRules: [
      v => !!v || 'Required'
    ]
  }),

  created () {
    this.codes = Object.entries(codes).map(([, v]) => v)
    const favoriteCountry = ['[US]', '[IL]', '[GB]', '[AU]']
    this.favorites = this.codes.filter((item) => {
      const [code] = item.split(' ')
      return favoriteCountry.includes(code)
    })
  },

  methods: {
    ...mapActions('Subscribe', ['subscribe']),
    setCode (item) {
      this.countryCode = item
      this.$nextTick(() => {
        this.phone = '+' + item.split('+')[1]
      })
      this.$refs.autocomplete.blur()
    },
    async subscribeEmail () {
      if (this.$refs.emailForm.validate()) {
        this.loading = true
        this.$refs.recaptcha.execute()
      }
    },
    async subscribePhone () {
      if (this.$refs.phoneForm.validate()) {
        this.loading = true
        this.$refs.recaptcha.execute()
      }
    },

    onVerify: async function (token) {
        let data
        if (this.selectedTab === 0){
          data = {
            accountId: this.$route.params.site,
            captcha: token,
            users: [{
              type: 'email',
              destination: this.email,
              min_sev: 2
            }]
          }
          this.emailPendingSub = true
        }
        else {
          data = {
            accountId: this.$route.params.site,
            captcha: token,
            users: [{
              type: 'sms',
              destination: this.phone,
              min_sev: 2
            }]
          }
          this.phonePendingSub = true;
        }
      await this.subscribe({...data})
      this.loading = false
    },

  }
}
</script>

<style scoped>

</style>
