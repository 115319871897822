<template>
  <div
      v-if="siteKey"
      id="g-recaptcha"
      class="g-recaptcha"
      :data-sitekey="siteKey">
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data () {
    return {
      widgetId: 0
    }
  },
  methods: {
    execute () {
      window.grecaptcha.execute(this.widgetId)
    },
    reset () {
      window.grecaptcha.reset(this.widgetId)
    },
    render () {
      if (window.grecaptcha) {
        this.widgetId = window.grecaptcha.render('g-recaptcha', {
          sitekey: this.siteKey,
          size: 'invisible',
          // the callback executed when the user solve the recaptcha
          callback: (response) => {
            // emit an event called verify with the response as payload
            this.$emit('verify', response)
            // reset the recaptcha widget so you can execute it again
            this.reset()
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters('Subscribe', [ 'getCaptchaSiteKey']),
    siteKey(){
      return this.getCaptchaSiteKey
    }
  },
  mounted () {
    // render the recaptcha widget when the component is mounted
    this.render()
  }
}
</script>