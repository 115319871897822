<template>
  <v-btn
    icon
    class="mx-2"
  >
    <v-icon color="success">
      mdi-check-circle-outline
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'Ok'
}
</script>

<style scoped>

</style>
