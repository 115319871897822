<template>
  <div>
    <ul class="d-lg-flex justify-md-space-between align-center">
      <li
        v-for="icon in iconsList"
        :key="icon"
        class="d-flex  align-center"
      >
        <component :is="dictionary[icon]" />
        <span>{{ icon }}</span>
      </li>
    </ul>

  </div>
</template>

<script>
import ok from '@/components/tooltipIcons/ok'
import degradation from '@/components/tooltipIcons/degradation'
import thirdparty from '@/components/tooltipIcons/thirdparty'
import downtime from '@/components/tooltipIcons/downtime'
import maintenance from '@/components/tooltipIcons/maintenanceIcon'
import suspected from '@/components/tooltipIcons/suspected'

export default {
  name: 'IconsList',
  components: {
    ok,
    degradation,
    thirdparty,
    downtime,
    maintenance,
    suspected
  },
  data: () => ({
    dictionary: {
      'Ok': 'ok',
      'Degradation': 'degradation',
      '3rd Party': 'thirdparty',
      'Downtime': 'downtime',
      'Maintenance':'maintenance',
      'Suspected': 'suspected'
    },
    iconsList: [
      'Ok',
      'Degradation',
      '3rd Party',
      'Downtime',
      'Maintenance',
      'Suspected'
    ]
  })
}
</script>

<style scoped>
ul{
  padding-left: 0;
}
</style>
