<template>
  <div class="mb-5">
    <v-row justify="center" align="center">
      <v-col cols="7">
        <div flat class="transparent pa-4 d-flex flex-column justify-center align-center">
          <v-icon :color="getIconColor" size="90">
            {{ getIcon }}
          </v-icon>
          <p class="text-h6 text-center" v-if="currentStatusImpactedServices">
            {{ currentStatusImpactedServices }}
          </p>
        </div>
      </v-col>
        <v-col  cols="12"  class="d-flex justify-end">
        <v-card max-width="270px" class="transparent" flat>
          <subscribe-modal />
        </v-card>
        </v-col>

      <v-col cols="12">
        <div class="glass-block content pa-4 mb-0 justify-space-around">
              <icons-list />
        </div>
      </v-col>
      <v-col cols="12">
        <div class="glass-block content pa-4 mb-0" >
          <status-list
              v-if="services"
            :services="services"
            @showIncident="openIncident"
            @showMaintenance="openMaintenance"
            @showCurrentStatus="showCurrentStatus"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SubscribeModal from '@/components/popup-components/subscribe-modal'
import StatusList from '@/components/dashboard/status-list'
import IconsList from '@/components/interface/icons-list'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Dashboard',
  components: {  IconsList, StatusList, SubscribeModal },
  computed: {
    ...mapGetters('Site', [ 'getUpdatedServices', 'getRealTimeIncidents', 'getOngoingMaintenance']),
    currentStatusImpactedServices () {
      if (this.currentStatus.length) {
        const impactedServices = [...new Set(this.currentStatus.map(status => status.service_impacted))]
        return `Impacted Services: ${impactedServices.join(', ')}.`
      } else {
        return false
      }
    },
    getIcon () {
      return this.currentStatus.length ? this.onlyMaintenance ? 'mdi-clock-outline' : 'mdi-alert' : 'mdi-check-circle'
    },
    currentStatus(){
      return [...this.ongoingMaintenance, ...this.realtimeIncidents]
    },
    getIconColor () {
      return this.currentStatus.length ? 'accent' : 'success'
    },
    services(){
      return this.getUpdatedServices
    },
    realtimeIncidents(){
      return this.getRealTimeIncidents
    },
    ongoingMaintenance(){
      return this.getOngoingMaintenance
    },

    onlyMaintenance(){
      return !this.realtimeIncidents.length && this.ongoingMaintenance.length
    },
    siteId () {
      return this.$route.params.site || null
    },
  },
  async created () {
    try{
      this.setLoading(true)
      await this.fetchServices()
      await this.getData(this.siteId)
    }catch (err){
      this.showPopup('Error loading site data')
      this.$router.push(`/404`)
    }
    finally {
      this.setLoading(false)
    }

  },
  methods: {
    ...mapActions('Tools', [ 'showPopup']),
    ...mapMutations('Tools', [ 'setLoading']),

    ...mapActions('Site', ['fetchIncidentData', 'getData', 'fetchServices']),
    openIncident (id) {
      const incident = this.currentStatus.filter(item => item.id === id)
      this.showIncident(incident[0])
    },
    openMaintenance (id) {
      const maintenance = this.currentStatus.filter(item => item.id === id)
      this.showMaintenance(maintenance[0])
    },
    showIncident (incident) {
      this.$router.push(`/current/${this.siteId}/incident/${incident.id}`)
    },
    showMaintenance (maintenance) {
      this.$router.push(`/current/${this.siteId}/maintenance/${maintenance.id}`)
    },
    showCurrentStatus(){
      this.$router.push(`/current/${this.siteId}`)
    },

  }
}
</script>

<style scoped lang="scss">

</style>
